var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-notes" } }),
          _c(
            "h5",
            { staticClass: "d-inline ml-2" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              _vm.isEdit
                ? [
                    _vm._v(" : "),
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v(_vm._s(this.itemName)),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: this.prevRoute } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _c("alert-section", {
            attrs: {
              successAlertMessage: _vm.successAlertMessage,
              dismissSecs: _vm.dismissSecs,
              dismissSuccessAlert: _vm.dismissSuccessAlert,
              errorAlertMessage: _vm.errorAlertMessage,
              showErrorAlert: _vm.showErrorAlert,
            },
          }),
          _c(
            "CForm",
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { col: "6" } },
                    [
                      _c("CInput", {
                        attrs: {
                          label: "Name",
                          placeholder: "Name",
                          lazy: false,
                          value: _vm.$v.form.name.$model,
                          isValid: _vm.checkIfValid("name"),
                          invalidFeedback: "This is a required field",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.$v.form.name, "$model", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { col: "6" } },
                    [
                      _c("CInput", {
                        attrs: {
                          label: "Note",
                          placeholder: "Note",
                          lazy: false,
                          value: _vm.$v.form.notes.$model,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.$v.form.notes, "$model", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { col: "12" } },
                    [
                      _vm.restaurantId == null
                        ? _c("CSelect", {
                            attrs: {
                              custom: "",
                              label: "Restaurant",
                              placeholder: "Please choose a restaurant..",
                              options: _vm.allRestaurants,
                              value: _vm.$v.form.restaurant_id.$model,
                              isValid: _vm.checkIfValid("restaurant_id"),
                              invalidFeedback: "This is a required field",
                            },
                            on: {
                              "update:value": [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.restaurant_id,
                                    "$model",
                                    $event
                                  )
                                },
                                (id) => _vm.getRestaurantAllCategories(id),
                              ],
                            },
                          })
                        : _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "d-block",
                                staticStyle: { "font-weight": "500" },
                              },
                              [_vm._v("Restaurant")]
                            ),
                            _c("strong", {
                              staticClass: "form-control",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.selectedRestaurant
                                    ? _vm.selectedRestaurant.label
                                    : ""
                                ),
                              },
                            }),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { staticClass: "card", attrs: { col: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-block",
                              staticStyle: { "font-weight": "500" },
                            },
                            [_vm._v("Categories")]
                          ),
                          _c("v-select", {
                            staticClass: "v-select-style",
                            attrs: {
                              multiple: "",
                              options: _vm.allCategories,
                              placeholder: "Select categories..",
                              disabled: _vm.form.restaurant_id == null,
                            },
                            model: {
                              value: _vm.$v.form.categories.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.categories, "$model", $$v)
                              },
                              expression: "$v.form.categories.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._l(this.form.categories, function (category) {
                        return _c(
                          "div",
                          { key: category.id, staticClass: "category-menu" },
                          [
                            _c("h6", { staticClass: "d-inline-block" }, [
                              _vm._v(_vm._s(category.label)),
                            ]),
                            _c("span", { staticClass: "small text-danger" }, [
                              _vm._v(" (Tick the following to be "),
                              _c("u", [_vm._v("excluded!")]),
                              _vm._v(")"),
                            ]),
                            _c("hr"),
                            _c(
                              "ul",
                              [
                                _vm._l(category.menus, function (menu) {
                                  return _c("li", { key: menu.id }, [
                                    _c("label", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.disabled_menus,
                                            expression: "form.disabled_menus",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          value: {
                                            category_id: menu.menu_cat,
                                            menu_id: menu.id,
                                          },
                                          checked: Array.isArray(
                                            _vm.form.disabled_menus
                                          )
                                            ? _vm._i(_vm.form.disabled_menus, {
                                                category_id: menu.menu_cat,
                                                menu_id: menu.id,
                                              }) > -1
                                            : _vm.form.disabled_menus,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.disabled_menus,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = {
                                                  category_id: menu.menu_cat,
                                                  menu_id: menu.id,
                                                },
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form,
                                                    "disabled_menus",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form,
                                                    "disabled_menus",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form,
                                                "disabled_menus",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        " " + _vm._s(menu.menu_name) + " "
                                      ),
                                    ]),
                                  ])
                                }),
                                category.menus && category.menus.length == 0
                                  ? _c("li", [
                                      _c("strong", [
                                        _vm._v("No menu groups found!"),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "sticky-search d-flex flex-row" }, [
                _c(
                  "div",
                  { staticClass: "input-group flex-grow-1 w-auto mr-2" },
                  [
                    _c("div", { staticClass: "input-group-prepend" }, [
                      _c(
                        "span",
                        { staticClass: "input-group-text" },
                        [
                          _c("CIcon", {
                            attrs: { name: "cil-magnifying-glass" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search,
                          expression: "search",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "search",
                        placeholder: "Search for menu groups..",
                      },
                      domProps: { value: _vm.search },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.search = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "align-self-start ml-auto" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: {
                          variant: "outline",
                          color: "primary",
                          pressed: _vm.isCollapsed,
                        },
                        on: {
                          "update:pressed": function ($event) {
                            _vm.isCollapsed = $event
                          },
                        },
                      },
                      [
                        _c("CIcon", {
                          attrs: {
                            name: `cil-chevron-${
                              _vm.isCollapsed ? "bottom" : "top"
                            }`,
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.isCollapsed ? "Expand" : "Collapse") +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    {
                      staticClass: "mt-2 table-responsive",
                      attrs: { col: "12" },
                    },
                    [
                      _c("h5", [_vm._v("Menu Groups (ingredients) Choice")]),
                      _c("table", { staticClass: "table" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticStyle: { width: "50px" } }),
                            _c(
                              "th",
                              { staticStyle: { width: "50px" } },
                              [_c("CIcon", { attrs: { name: "cil-move" } })],
                              1
                            ),
                            _c("th", { staticStyle: { width: "20vw" } }, [
                              _vm._v("Menu Groups"),
                            ]),
                            _c("th", { staticStyle: { width: "80vw" } }, [
                              _vm._v("Ingredients"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.allMenuGroups, function (mg) {
                            return _c(
                              "tr",
                              {
                                key: mg.id,
                                class: _vm.isSelectedMenuGroup(mg.id)
                                  ? "selected-item"
                                  : "disable-item",
                              },
                              [
                                _c(
                                  "td",
                                  { staticClass: "enable-item" },
                                  [
                                    _c("CInputCheckbox", {
                                      attrs: {
                                        addLabelClasses:
                                          "custom-checkbox-label",
                                        custom: "",
                                        checked: _vm.isSelectedMenuGroup(mg.id),
                                      },
                                      on: {
                                        "update:checked": (value) =>
                                          _vm.selectMenuGroup(mg.id, value),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "move-item" },
                                  [
                                    _c("CIcon", {
                                      staticClass: "align-bottom",
                                      attrs: {
                                        name: "cil-move",
                                        "data-smg-id":
                                          _vm.getSelectedMenuGroupId(mg.id),
                                        "data-mg-id": mg.id,
                                        size: "lg",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "vertical-align": "top !important",
                                    },
                                  },
                                  [
                                    _c("h5", [_vm._v(_vm._s(mg.Name))]),
                                    _c("span", [_vm._v(_vm._s(mg.Notes))]),
                                    _vm.isSelectedMenuGroup(mg.id)
                                      ? _c(
                                          "section",
                                          { staticClass: "border-top pt-3" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "mr-1" },
                                                  [_vm._v("Type:")]
                                                ),
                                                _c(
                                                  "select",
                                                  {
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeTypeListing(
                                                          mg.id,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "SLT" },
                                                      },
                                                      [_vm._v("ListBox")]
                                                    ),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "CHK" },
                                                        domProps: {
                                                          selected:
                                                            _vm.selectedTypeListing(
                                                              mg.id,
                                                              "CHK"
                                                            ),
                                                        },
                                                      },
                                                      [_vm._v("CheckBox")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-group w-100",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "d-block" },
                                                  [_vm._v("Valid Days *")]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "day-group" },
                                                  _vm._l(
                                                    _vm.days,
                                                    function (day, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-check form-check-inline mr-1 mt-1",
                                                        },
                                                        [
                                                          _c("CInputCheckbox", {
                                                            attrs: {
                                                              label: day,
                                                              checked: _vm
                                                                .selectedValidDays(
                                                                  mg.id
                                                                )
                                                                ?.includes(
                                                                  index
                                                                ),
                                                            },
                                                            on: {
                                                              "update:checked":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.updateValidDays(
                                                                    mg.id,
                                                                    index
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c("small", [
                                                  _vm._v(
                                                    "* All days are valid if none is selected"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "enable-item" },
                                  [
                                    _c(
                                      "CCollapse",
                                      { attrs: { show: _vm.isCollapsed } },
                                      [
                                        _c("ul", [
                                          _c(
                                            "li",
                                            { staticClass: "text-muted" },
                                            [
                                              _c("CIcon", {
                                                attrs: {
                                                  name: "cil-chevron-bottom",
                                                },
                                              }),
                                              _vm._v(" Collapsed"),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "transition",
                                      { attrs: { name: "fade" } },
                                      [
                                        _c(
                                          "CCollapse",
                                          {
                                            attrs: {
                                              show: !_vm.isCollapsed,
                                              duration: 400,
                                            },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              [
                                                _vm._l(
                                                  mg.menu_ext_group_childs,
                                                  function (child) {
                                                    return _c(
                                                      "li",
                                                      { key: child.id },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "for-checkbox",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.form
                                                                      .selectedChilds,
                                                                  expression:
                                                                    "form.selectedChilds",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "checkbox",
                                                              },
                                                              domProps: {
                                                                value: {
                                                                  menuExtGroupId:
                                                                    mg.id,
                                                                  menuExtGroupChildId:
                                                                    child.id,
                                                                },
                                                                checked:
                                                                  Array.isArray(
                                                                    _vm.form
                                                                      .selectedChilds
                                                                  )
                                                                    ? _vm._i(
                                                                        _vm.form
                                                                          .selectedChilds,
                                                                        {
                                                                          menuExtGroupId:
                                                                            mg.id,
                                                                          menuExtGroupChildId:
                                                                            child.id,
                                                                        }
                                                                      ) > -1
                                                                    : _vm.form
                                                                        .selectedChilds,
                                                              },
                                                              on: {
                                                                change: [
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        _vm.form
                                                                          .selectedChilds,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c =
                                                                        $$el.checked
                                                                          ? true
                                                                          : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v =
                                                                          {
                                                                            menuExtGroupId:
                                                                              mg.id,
                                                                            menuExtGroupChildId:
                                                                              child.id,
                                                                          },
                                                                        $$i =
                                                                          _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "selectedChilds",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v,
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "selectedChilds",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        "selectedChilds",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectingChild(
                                                                      mg.id
                                                                    )
                                                                  },
                                                                ],
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  child.Name
                                                                ) +
                                                                " [" +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "toCurrency"
                                                                  )(child.Price)
                                                                ) +
                                                                "] "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                mg.menu_ext_group_childs
                                                  .length == 0
                                                  ? _c("li", [
                                                      _c("strong", [
                                                        _vm._v(
                                                          "No menu group childs found!"
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardFooter",
        { staticClass: "sticky-bottom" },
        [
          _c(
            "CButton",
            {
              attrs: { color: "primary", disabled: _vm.submitted },
              on: {
                click: function ($event) {
                  _vm.isEdit ? _vm.update() : _vm.store()
                },
              },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.8, mtop: 32 + _vm.mcSpinnerMarginTop + "px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }